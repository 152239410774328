import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import toastr from "toastr";
import { Form, Input, Button, Row, Col, InputNumber, Select } from "antd";
import { JobsServiceApi, UserServiceApi } from "../../api";
import { putRequest, getRequest } from "../../api/http";
import ProfileLogo from "../../assets/images/companyProfile.png";
import { LoadingView } from "../common";
import "./index.scss";

const EditProfile = () => {
  const token = localStorage.getItem("token");
  const [imgData, setImgData] = useState(null);
  const [selectedSignature, setSelectedSignature] = useState(null);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [gstinOrPanNumber, setGstinOrPanNumber] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  let employerData = location.state.employerData;
  const { Option } = Select;

  const fetchProfileImage = async () => {
    try {
      const response = await getRequest(UserServiceApi.getProfileImage());
      if (response.profileUrl !== null) {
        setSelectedSignature(response.profileUrl);
        setIsLoading(false);
      } else {
        setSelectedSignature(ProfileLogo);
        setIsLoading(false);
      }
    } catch (e) {
      toastr.error(e.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileImage();
  }, []);

  const onChangePicture = (e) => {
    const imageValid = ["jpg", "png", "jpeg"];
    const fileName = e.target.files[0].name;
    const fileSize = e.target.files[0].size;
    const fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if (imageValid.includes(fileNameExt) && fileSize <= 5242880) {
      uploadProfile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
        setSelectedSignature(null);
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      toastr.warning(
        "The image size should be less than 5 MB. And it will accept png, jpg, and jpeg formats only."
      );
    }
  };

  const uploadProfile = async (value) => {
    const formData = new FormData();
    formData.append("file", value);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    };

    axios
      .post(process.env.REACT_APP_API_BASE_URL + "/profile-picture", formData, {
        headers,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        toastr.error(e?.data?.message);
      });
  };

  const saveProfile = async (values) => {
    console.log(values, "values");
    let address = values?.address
    address = address.replace(/\n/g, "");
    let companyDescription=values?.companyDescription
    companyDescription=companyDescription?.replace(/\n/g, "")
    setBtnLoading(true);
    const submitData = {
      phone: "+91" + values?.phone,
      address: address,
      city: values?.city,
      companyDescription: companyDescription,
      companyName: values?.companyName,
      email: values?.email,
      employerUsername: values?.employerUsername,
      gstin: values?.gstin,
      pan: values?.pan,
      pincode: values?.pincode,
      state: values?.state,
      employerGender: values?.employerGender,
    };
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await putRequest(
        JobsServiceApi.UpdateEmployerProfile(),
        submitData
      );
      if (response) {
        toastr.success("Profile updated successfully");
        navigate("/profile");
        setBtnLoading(false);
        if (response) {
          window.location.reload();
        }
      }
    } catch (e) {
      toastr.error(e.message);
      setBtnLoading(false);
    }
  };
  if (isLoading) return <LoadingView />;

  const handleGstinOrPanNumber = (value) => {
    setGstinOrPanNumber(value);
  };

  return (
    <div className="editProfile">
      <div className="headingContainer">
        <div className="headingTitle">
          <h3>Profile </h3>
        </div>
        <div className="headingButton">
          <Button
            className="btn ant-btn ant-btn-primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="profile">
        <Row gutter={16}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <div className="signatureBox">
              {imgData !== null && (
                <React.Fragment>
                  <p>
                    The image size should be less than 5 MB. And it will accept
                    png, jpg, and jpeg formats only.
                  </p>
                  <div className="signatureBoxDisplayProfile">
                    <img alt="text" src={imgData} />
                  </div>
                </React.Fragment>
              )}
              {selectedSignature !== null && (
                <React.Fragment>
                  {employerData?.registeredAs === "COMPANY" ? (
                    <p>
                      The image size should be less than 5 MB. And it will
                      accept png, jpg, and jpeg formats only.
                    </p>
                  ) : null}
                  <div className="signatureBoxDisplayProfile">
                    <img src={selectedSignature} alt="Signature" />
                  </div>
                </React.Fragment>
              )}
              {employerData?.registeredAs === "COMPANY" ? (
                <div className="signatureBoxProfile">
                  <label htmlFor="files" className="btn">
                    Choose file{" "}
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={onChangePicture}
                  />
                </div>
              ) : null}
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <div className="editProfileForm">
              <Form
                layout="vertical"
                className="profileFormItems"
                initialValues={
                  employerData
                    ? {
                        companyName: employerData?.companyName,
                        companyDescription: employerData?.companyDescription,
                        employerUsername: employerData?.contactName,
                        email: employerData?.email,
                        phone: employerData?.phone
                          ? employerData?.phone?.slice(-10)
                          : "",
                        address: employerData?.address,
                        city: employerData?.city,
                        state: employerData?.state,
                        pincode: employerData?.pincode,
                        gstin: employerData?.gstin,
                        pan: employerData?.pan,
                        employerGender: employerData?.employerGender,
                      }
                    : null
                }
                onFinish={(e) => saveProfile(e)}
              >
                {employerData?.registeredAs === "COMPANY" ? (
                  <>
                    <Form.Item
                      label="Company Name"
                      className="formLabel"
                      name="companyName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter company name",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Company Name"
                        className="formInput"
                        disabled
                      />
                    </Form.Item>
                    {!employerData?.gstin && !employerData?.pan ? (
                      <Form.Item
                        name="gstinOrPanEnum"
                        className="formLabel"
                        label="Select GSTIN or PAN"
                        rules={[
                          {
                            required: true,
                            message: "Please select employer register type",
                          },
                        ]}
                      >
                        <Select
                          className="formInput"
                          onChange={handleGstinOrPanNumber}
                          placeholder="Please select employer register type"
                        >
                          <Option value="GSTIN">GSTIN No</Option>
                          <Option value="PAN">PAN No</Option>
                        </Select>
                      </Form.Item>
                    ) : null}
                    {employerData?.gstin || gstinOrPanNumber === "GSTIN" ? (
                      <Form.Item
                        label="GSTIN No"
                        name="gstin"
                        className="formLabel"
                        rules={[
                          {
                            required: true,
                            message: `GSTIN No cannot be Empty`,
                          },
                          () => ({
                            validator(_, value) {
                              if (
                                !/^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/.test(
                                  value
                                )
                              ) {
                                return Promise.reject(
                                  `Please Enter Valid GSTIN No`
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        validateFirst={true}
                      >
                        <Input
                          placeholder="Please enter GSTIN No"
                          className="formInput"
                        />
                      </Form.Item>
                    ) : null}
                    {employerData?.pan || gstinOrPanNumber === "PAN" ? (
                      <Form.Item
                        label="PAN No"
                        name="pan"
                        className="formLabel"
                        rules={[
                          { required: true, message: `PAN No cannot be Empty` },
                          () => ({
                            validator(_, value) {
                              if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(value)) {
                                return Promise.reject(
                                  `Please Enter Valid GSTIN No`
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        validateFirst={true}
                      >
                        <Input
                          placeholder="Please enter GSTIN No"
                          className="formInput"
                        />
                      </Form.Item>
                    ) : null}
                    <Form.Item
                      label="Company Description"
                      className="formLabel"
                      name="companyDescription"
                      rules={[
                        {
                          required: true,
                          message: "Please enter company description",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Please enter company description"
                        className="textArea"
                        // maxLength={1000}
                      />
                    </Form.Item>
                  </>
                ) : null}
                <Form.Item
                  label="Contact Name"
                  className="formLabel"
                  name="employerUsername"
                  rules={[
                    { required: true, message: "Please enter contact name" },
                  ]}
                >
                  <Input
                    placeholder="Please enter contact name"
                    className="formInput"
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  className="formLabel"
                  name="email"
                  rules={[
                    { required: true, message: "Please enter email" },
                    {
                      type: "email",
                      message: "Please enter valid email!",
                    },
                  ]}
                >
                  <Input
                    placeholder="EmployerName@gamil.com"
                    className="formInput"
                    type="email"
                    disabled
                  />
                </Form.Item>
                {employerData?.registeredAs === "INDIVIDUAL" ? (
                  <Form.Item
                    name="employerGender"
                    label="Select gender"
                    className="formLabel"
                    rules={[
                      { required: true, message: "Please select gender" },
                    ]}
                  >
                    <Select placeholder="Please select gender">
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                    </Select>
                  </Form.Item>
                ) : null}
                <Form.Item
                  label="Phone"
                  className="formLabel"
                  name="phone"
                  rules={[
                    { required: true, message: "Please enter phone number" },
                  ]}
                >
                  <InputNumber
                    addonBefore={<span>+91</span>}
                    placeholder="+456 9871 023"
                    className="formInput contactNumber"
                    type="number"
                  />
                </Form.Item>
                <Form.Item
                  label="Address"
                  className="formLabel"
                  name="address"
                  rules={[
                    { required: true, message: "Please enter address" },
                    {
                      pattern: /^[A-Za-z0-9#(),\/.\-\s]+$/,
                      message:
                        "Alphanumeric characters, spaces, and special characters like # (hash), comma, circular brackets, slash, dot, and hyphen are allowed.",
                    },
                    {
                      max: 150,
                      message: "Address cannot exceed 150 characters.",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="24,Garden Layout Bangalore,560045"
                    className="textArea"
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    // maxLength={1000}
                  />
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                  className="formLabel"
                  // rules={[{ required: true, message: 'Please enter city' }]}
                >
                  <Select
                    placeholder="Please select city"
                    className="formInput"
                    allowClear
                    disabled
                  >
                    <Option value="Mumbai">Mumbai</Option>
                    <Option value="Kolkata">Kolkata</Option>
                    <Option value="Chennai">Chennai</Option>
                    <Option value="Bangalore">Bangalore</Option>
                    <Option value="Hyderabad">Hyderabad</Option>
                    <Option value="Pune">Pune</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="State"
                  name="state"
                  className="formLabel"
                  // rules={[{ required: true, message: 'Please enter state' }]}
                >
                  <Select
                    placeholder="Please select state"
                    className="formInput"
                    allowClear
                    disabled
                  >
                    <Option value="Delhi">Delhi</Option>
                    <Option value="Karnataka">Karnataka</Option>
                    <Option value="Chennai">Maharashtra</Option>
                    <Option value="Tamil Nadu">Tamil Nadu</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Pin code"
                  name="pincode"
                  className="formLabel "
                  rules={[{ required: true, message: "Please enter pin code" }]}
                >
                  <InputNumber
                    className="formInput"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item className="saveBtn">
                  <Button
                    type="primary submit"
                    htmlType="submit"
                    className="formButton"
                    loading={isBtnLoading}
                  >
                    {!isBtnLoading ? "Save" : "Loading..."}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default EditProfile;
